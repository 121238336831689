@import '~antd/dist/antd.css';
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  font-size: 16px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td {
  border: 1px solid;
}

.ant-pro-basicLayout-content {
  position: relative;
  margin: 0 24px;
}

.ant-pro-global-header {
  background-color: #5abbb2;
}

.slate-p{
  font-size: 0.95rem;
}

.slate-file{
  height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 14px;
    background-color: #f5f5f5;
}

.slate-file a{
  cursor: pointer;
}
.slate-file button{
  margin-right:5px;
  margin-top: 8px;
  float: right;
}

.ant-dropdown-menu{
  top: 20px;
  padding: 20px !important;
  min-width: 100px;
  max-width: 200px;
  overflow: hidden;
}

.ant-timeline-item{
  padding-bottom: 0;
}

.ant-card-body{
  padding: 40px;
}
